$pensilBlue: #0445fe;
$pensilRed: #ff0000;
$pensilGreen: #0e9d58;
$pensilDarkGray: #c8c8c8;

$vitalizeBlue: #0445fe;
$vitalizeRed: #ff0000;
$vitalizeGreen: #0e9d58;
$vitalizeDarkGray: #c8c8c8;

$vitalizeLightBlue: #6f83e0;
$dark-grey: #6a6e82;
$bg-purple: #e0e5fe;
$border-primary: rgba(111, 131, 224, 0.27);
