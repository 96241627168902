@import 'variables';

.text-theme-primary {
    color: $vitalizeBlue;
}

.border-theme-primary {
    border-color: $vitalizeBlue;
}
.border-vitalize-red {
    border-color: $vitalizeRed;
}
.border-vitalize-green {
    border-color: $vitalizeGreen;
}
.border-vitalize-darkgray {
    border-color: $vitalizeDarkGray;
}
.hover\:border-theme-primary:hover {
    border-color: $vitalizeBlue;
}

.bg-theme-primary {
    background-color: $vitalizeLightBlue;
}

.bg-vitalize-red {
    background-color: $vitalizeRed;
}
.bg-vitalize-green {
    background-color: $vitalizeGreen;
}
.bg-vitalize-darkgray {
    background-color: $vitalizeDarkGray;
}

.border-3 {
    border-width: 3px;
    border-style: solid;
}

.bg-default-grey {
    background: #fafafb;
}

.text-xxs {
    font-size: 0.65rem;
}

/* Animations */

.spinner {
    animation: spinner 0.75s ease-in-out infinite;
}

@keyframes spinner {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

.animated {
    transition: all 0.4s;
}

ol,
ul {
    list-style: initial;
    margin: initial;
    padding: 0.75rem;
    padding-left: 2rem;
}
ol {
    list-style: decimal;
}
